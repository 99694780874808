import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

const Service = ({
  icon,
  title,
  description,
  subTitle1,
  subTitle2,
  subDescription1,
  subDescription2,
  serviceNum,
  direction,
  withoutWrapper,
  picture
}) => {

  // const { language } = useLanguage()
  const [toggleSubOne, setToggleSubOne] = useState(false)

  const [toggleSubTwo, setToggleSubTwo] = useState(false)

  const toggleSubTwoOpen = () => {
    setToggleSubTwo(!toggleSubTwo)
  }
  const toggleSubOneOpen = () => {
    setToggleSubOne(!toggleSubOne)
  }

  return (
    <ServiceContainer
      data-sal={`slide-${direction}`}
      data-sal-duration="2000"
      data-sal-delay="200"
      data-sal-easing="ease"
      withoutWrapper
      id={title}
      idNum={serviceNum}
    >
      {withoutWrapper && picture && (
        <Img
          idNum={serviceNum}
          className="img-service"
          fluid={picture?.asset?.fluid}
        />
      )}
      <div className="service-container">
        <div className="header">
          <img
            style={{ marginRight: "20px" }}
            height="50px"
            width="50px"
            src={icon}
            alt=""
          />
          <h3 className="title-services">{title} </h3>
        </div>

        {/* <h1 className="list-num">{serviceNum}</h1> */}
        <p className="body">{description}</p>

        {subTitle1 && (
          <>
            <div
              className={`sub ${!toggleSubOne && "closed"}`}
              onClick={toggleSubOneOpen}
            >
              <h5 className="sub-title">{subTitle1}</h5>
              <button className="arrow">{toggleSubOne ? "↑" : "↓"}</button>
            </div>
            {toggleSubOne && (
              <>
                <ul className={`body sub-body ${toggleSubOne && "open"}`}>
                  {Array.isArray(subDescription1)
                    ? subDescription1.map(el => {
                        if (el) return <li>{el}</li>
                      })
                    : subDescription1}
                </ul>
              </>
            )}
          </>
        )}

        {subTitle2 && (
          <>
            <div
              className={`sub ${!toggleSubTwo && "closed"}`}
              onClick={toggleSubTwoOpen}
            >
              <h5>{subTitle2}</h5>
              <button className={`arrow `}>{toggleSubTwo ? "↑" : "↓"}</button>
            </div>
            {toggleSubTwo && (
              <p className={`body sub-body ${toggleSubTwo && "open"}`}>
                {subDescription2}
              </p>
            )}
          </>
        )}
      </div>
    </ServiceContainer>
  )
}

export default Service

const ServiceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 8rem;


.gatsby-image-wrapper{
  width: 100% !important;
  height:100% !important;
}
  .list-num {
    color: var(--beige);
    margin-bottom: 4px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .title-services {
  }

  .sub-one {
    margin: 32px 0 16px 0;
  }

.sub-body{
  list-style-type:disc !important;
}

.body{
  text-align: justify
}
  .sub {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;
  }

  .service-container {
    width: 100%;
  }
  .open {
    border: none;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .closed {
    border: 1px solid var(--beige);
    border-left: none;
    border-right: none;
  }

  .arrow {
    background: none;
    border: none;
    :hover {
      cursor: pointer;
    }
  }

  .img-service {
    /* width: 100%; */
    width: 40%;
  }

  @media (min-width: 1440px) {
    /*XL*/

    /* margin: ${props =>
      props.idNum == "01"
        ? "0 240px 112px "
        : props.idNum == "04"
        ? "0px 240px 112px "
        : "0 0 112px 0 "}; */
    margin: 0 0 112px 0;

    .service-container {
      /* padding: ${props =>
        props.idNum == "02" || props.idNum == "03" ? "0 80px" : ""}; */
      padding: 0 80px;
    }

    flex-direction: ${props => (props.idNum == "03" || props.idNum =="01"? "row-reverse" : "")};
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    /* L */
    // color: blue;
    flex-direction: ${props => (props.idNum == "03" || props.idNum =="01"? "row-reverse" : "")};

    /* margin: ${props =>
      props.idNum == "01"
        ? "0 80px 160px "
        : props.idNum == "04"
        ? "0px 80px 112px"
        : "0px 0px 160px"}; */
    margin:0px 0px 160px;

    .service-container {
      /* padding: ${props =>
        props.idNum == "02"
          ? "0  0 0 80px "
          : props.idNum == "03"
          ? "0 80px 0 0"
          : ""}; */
      padding: ${props =>
        props.idNum == "02" || props.idNum === "04"
          ? "0  0 0 80px "
          : props.idNum == "03"|| props.idNum === "01"
          ? "0 80px 0 0"
          : ""};
    }

    .img-service {
      width: 60%;
      height: 100%;
    }
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    /* M */
    .service-container {
      align-items: center;
    }
    flex-direction: column;

    margin-bottom: ${props => (props.idNum == "04" ? "112px" : "96px")};
    .img-service {
      width: 100%;
      height: 100%;
      margin-bottom: 32px;
    }
  }

  @media (max-width: 600px) {
    /* S */
    flex-direction: column;

    margin-bottom: ${props => (props.idNum == "04" ? "112px" : "96px")};

    .img-service {
      width: 100%;
      height: 100%;
      margin-bottom: 32px;
    }
  }
`
