import React from "react"
import styled from "styled-components"
import { useLanguage } from "../../context/LanguageContext"
import { Link } from "gatsby"
import { ContentWrapper } from "../../styles/Wrapper"

const DesktopServiceBar = ({ serviceArr }) => {
  const { language } = useLanguage()

  return (
    <Bar className="full">
      {/* <ContentWrapper> */}
      <ServiceBar>
        <ul
          data-sal="fade"
          data-sal-duration="600"
          data-sal-delay="600"
          data-sal-easing="easeInSine"
        >
          {serviceArr.map((el, i) => (
            <Link
              key={i}
              cover="true"
              to={`/services/#${el[language]}`}
              bg="var(--beige)"
            >
              <li key={el.de}>
                <img src={el.img} alt={el[language]} />
                <div className="links">{el[language]}</div>
              </li>
            </Link>
          ))}
          {/* <li>
          <img src={speechBubble} alt="" />
          <h5 className="links">hi</h5>
        </li>
        <li>
          <img src={calculator} alt="" />

          <h5 className="links">test</h5>
        </li>
        <li>
          <img src={graphBar} alt="" />

          <h5 className="links">test</h5>
        </li>
        <li>
          <img src={notebook} alt="" />

          <h5 className="links">test</h5>
        </li> */}
        </ul>
      </ServiceBar>
      {/* </ContentWrapper> */}
    </Bar>
  )
}

export { DesktopServiceBar }

const Bar = styled(ContentWrapper)`
  width: 100%;
`

const ServiceBar = styled.div`
  z-index: 10;
  background: white;
  border-radius: 6px;
  padding: 40px 0;
  /* border: 1px solid green; */
  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;

    li {
      text-decoration: none;
      color: var(--grey);
      display: flex;

      :hover {
        cursor: pointer;
      }

      :focus {
        color: black;
      }
    }
  }

  img {
    margin-right: 1rem;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    /* M */
  }
`
