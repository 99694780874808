import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const SingleServiceComponent = ({ img, service }) => {
  return (
    <Link
      cover="true"
      className="links"
      to={`/services/#${service}`}
      bg={"var(--beige)"}
    >
      <SingleService service={service.length}>
        <div className="singleContent" key={service}>
          <img src={img} alt={service} />
          <div className="links">{service}</div>
        </div>
      </SingleService>
    </Link>
  )
}

const SingleService = styled.article`
  background: white;
  height: 12rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--blue);
  border-radius: 6px;
  padding: 0 1rem 0 1re m;
  margin: 10px 0;
  .singleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      height: 3.2rem;
      margin-bottom: 1.6rem;
    }
    .links {
      text-align: center;
      word-break: break-word;
    }
  }
`

export { SingleServiceComponent }
