import React from "react"
import { ContentWrapper } from "../../styles/Wrapper"
import { useLanguage, useNav } from "../../context"
import ServicesBar from "../templates/ServicesBar"
import styled from "styled-components"
import Observer from "@researchgate/react-intersection-observer"
import { Landing } from "../../styles/Landing"

export const ServiceLanding = ({ main, servicesList }) => {
  const { language } = useLanguage()
  const { checkIntersection } = useNav()
  function chooseText(path, mainObj = main, lang = language) {
    return mainObj[`${path}_${lang}`]
  }
  return (
    <Landing style={{ paddingTop: "var(--navHeight)" }}>
      <ContentWrapper>
        <div
          className="content"
          data-sal="fade"
          data-sal-duration="600"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <Observer rootMargin="-100px 0% -15%" onChange={checkIntersection}>
            <h6 className="sections">{chooseText("sectionTitle")}</h6>
          </Observer>
          <h2>{chooseText("headline")}</h2>
        </div>
        <ServicesBar servicesList={servicesList} />
      </ContentWrapper>
    </Landing>
  )
}

export default ServiceLanding
