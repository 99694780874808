import React from "react"
import { useLanguage } from "../../context/LanguageContext"
import styled from "styled-components"
import { SingleServiceComponent } from "./"
import { ContentWrapper } from "../../styles/Wrapper"

const MobileServiceBarComponent = ({ serviceArr }) => {
  const { language } = useLanguage()
  return (
    <MobileServiceBar className="mobile">
      <ContentWrapper className="test">
        <ul>
          {serviceArr.map((el, i) => (
            <SingleServiceComponent
              key={i}
              img={el.img}
              service={el[language]}
            />
          ))}
        </ul>
      </ContentWrapper>
    </MobileServiceBar>
  )
}

const MobileServiceBar = styled.div`
  width: 100vw;
  /* scrollbar-width: none; */
  /*  ::-webkit-scrollbar {
    display: none;
  }
 */
  .test {
    overflow-x: scroll;
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 146, 154, 0.4);
      background-color: white;
      margin: 100px 0;
      border-radius: 6px;
    }

    ::-webkit-scrollbar {
      border-radius: 6px;
      border: solid 3px transparent;
      margin: 100px 0;
      background-color: rgba(0, 146, 154, 0.4);
      height: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      border: solid 3px transparent;
      background-color: var(--blue);
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.6rem;
    justify-content: space-between;
    /* width: 175vw; */
  }
`

export { MobileServiceBarComponent }
