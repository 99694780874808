import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import ServicesBar from "../components/templates/ServicesBar"
import Service from "../components/templates/Service"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentWrapper } from "../styles/Wrapper"
import { OutlinedButtons, Button } from "../styles/Buttons"
import { useLanguage } from "../context/LanguageContext"
import { services2 } from "../utils/imageUpload"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useNav } from "../context"
import { ServiceLanding } from "../components/ServicesPage"
import { useSpecialPage } from "../hooks/useSpecialPage.hook"

import {
  Banner,
  notebook,
  graphBar,
  calculator,
  speechBubble
} from "../utils/imageUpload"
const Landing = styled.div`
  background: var(--beige);
  display: block;
  position: relative;
  .content {
    h2 {
      margin-top: 16px;
    }
  }

  @media (min-width: 1440px) {
    .content {
      padding: 80px;
      padding-bottom: 13rem;
    }
  }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .content {
      padding: 80px 0;
      padding-bottom: 13rem;
    }
  }
  @media (max-width: 1023px) {
    .content {
      padding: 56px 0;
      padding-bottom: 13rem;
    }
  }
`

const ServicesPage = ({ data }) => {
  const [main] = data.allSanityServicesPage.edges.map(({ node }) => node)
  useSpecialPage(false)
  const { ref } = useNav()
  const [servicesList] = data.allSanityHomepage.edges.map(({ node }) => node)

  const { language } = useLanguage()


  function chooseText(path, mainObj = main, lang = language) {
    return mainObj[`${path}_${lang}`]
  }

  return (
    <Layout>
      <SEO title="Services" />
      <ServiceLanding main={main} servicesList={servicesList} />
      <Services>
        <ContentWrapper>
          <Service
          withoutWrapper
          picture={main.servieImgOne}

            direction="right"
            icon={speechBubble}
            serviceNum="01"
            title={chooseText("serviceTitle1")}
            description={chooseText("serviceDescription1")}
            subTitle1={chooseText("serviceSubTitle1")}
            subTitle2={chooseText("serviceSubTitle2")}
            subDescription1={chooseText("serviceSubDescription1")}
            subDescription2={chooseText("serviceSubDescription2")}
          />
          <Service
            icon={calculator}
            direction="left"
            withoutWrapper
            picture={main.servieImgTwo}
           
            serviceNum="02"
            title={chooseText("serviceTitle2")}
            description={chooseText("serviceDescription2")}
            subTitle1={chooseText("serviceSubTitle")}
            subDescription1={chooseText("service2SubDescription2")}
          />
          <Service
            icon={graphBar}
            direction="right"
            withoutWrapper
            picture={main.servieImgThree}
            serviceNum="03"
            title={chooseText("serviceTitle3")}
            description={chooseText("serviceDescription3")}
            // subTitle1={chooseText("serviceSubTitle1")}
            // subDescription1={chooseText("serviceSubDescription1")}
          />{" "}
          <Service
             withoutWrapper
             picture={main.servieImgFour}
            icon={notebook}
            direction="left"
            serviceNum="04"
            title={chooseText("serviceTitle4")}
            description={chooseText("serviceDescription4")}
            // subTitle1={chooseText("serviceSubTitle1")}
            // subDescription1={chooseText("serviceTitle1")}
          />
          <Action
            data-sal="fade"
            data-sal-duration="400"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            <div>
              <p className="body">{chooseText("buttonAction")}</p>
              <h3>{chooseText("secondHeadline")}</h3>
            </div>
            <AniLink cover to="/contact" bg="var(--green)">
              <Button>{chooseText("emailOpen")}</Button>
            </AniLink>
          </Action>
        </ContentWrapper>
      </Services>
    </Layout>
  )
}

export default ServicesPage

const Action = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;

  .body {
    margin-bottom: 8px;
  }
  @media (min-width: 1440px) {
    margin: 0 112px;

    padding: 32px 48px;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    /* L */
    margin: 0 32px;
    padding: 32px 48px;
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    /* M */
    padding: 32px;
    margin: 0;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 600px) {
    /* S */
    padding: 24px;
    margin: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h3 {
      margin-bottom: 24px;
    }
  }
`

const Services = styled.div`
  background: var(--backgrounds);
  padding: 13rem 0;
  display: block;
`

export const query = graphql`
  query {
    allSanityHomepage {
      edges {
        node {
          servicesList_de
          servicesList_en
        }
      }
    }
    allSanityServicesPage {
      edges {
        node {
          headline_de
          headline_en
          secondHeadline_de
          secondHeadline_en
          sectionTitle_de
          sectionTitle_en
          imageOne {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageTwo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          servieImgOne {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          } servieImgTwo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          } servieImgThree {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          } servieImgFour {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          serviceSubTitle1_de
          serviceSubTitle1_en
          serviceSubTitle2_de
          serviceSubTitle2_en
          serviceTitle1_de
          serviceTitle1_en
          serviceTitle2_de
          serviceTitle2_en
          serviceTitle3_de
          serviceTitle3_en
          serviceTitle4_de
          serviceTitle4_en
          buttonAction_de
          buttonAction_en
          buttonContact_de
          buttonContact_en
          emailOpen_de
          emailOpen_en
          serviceDescription1_de
          serviceDescription1_en
          serviceDescription2_de
          serviceDescription2_en
          serviceDescription3_de
          serviceDescription3_en
          serviceDescription4_de
          serviceDescription4_en
          serviceSubDescription1_de
          serviceSubDescription1_en
          serviceSubDescription2_de
          serviceSubDescription2_en
          service2SubDescription2_de
          service2SubDescription2_en
          serviceSubTitle_de
          serviceSubTitle_en
        }
      }
    }
  }
`
