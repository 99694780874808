import React from "react"
import styled from "styled-components"

import {
  notebook,
  graphBar,
  calculator,
  speechBubble
} from "../../utils/imageUpload"
import { DesktopServiceBar, MobileServiceBarComponent } from "../ServiceBar"

const serviceBarImageArr = [speechBubble, calculator, graphBar, notebook]

const ServicesBar = styled.main`
  position: absolute;
  width: 100vw;
  bottom: -60px;
  left: 0;

  .mobile {
    display: block;
  }

  .full {
    display: none;
  }

  @media (min-width: 1024px) {
    bottom: -50px;
    .full {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
`

const ServicesBarComponent = ({ servicesList }) => {

  const serviceArr = new Array(servicesList.servicesList_en.length)
    .fill()
    .map((_, i) => ({
      de: servicesList.servicesList_de[i],
      img: serviceBarImageArr[i],
      en: servicesList.servicesList_en[i]
    }))

  return (
    <ServicesBar>
      <DesktopServiceBar serviceArr={serviceArr} />
      <MobileServiceBarComponent serviceArr={serviceArr} />
    </ServicesBar>
  )
}

export default ServicesBarComponent

/* export const query = graphql`
  query {
    allSanityServicesPage {
      edges {
        node {
          serviceTitle1_de
          serviceTitle1_en
          serviceTitle2_de
          serviceTitle2_en
          serviceTitle3_de
          serviceTitle3_en
          serviceTitle4_de
          serviceTitle4_en
        }
      }
    }
  }
`
 */
